import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/products', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/products/${id}/show`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchExchangeRate(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/setting/exchange-rate')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductCategoriesList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/product/categories/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductBrands(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/product/brands')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHistories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/history/product', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/product/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/product/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/product/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProductStock(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/product/stock', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateImageGallery(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/product/${id}/gallery-update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteImageGallery(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/product/gallery-delete', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cloneProduct(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/product/${id}/clone`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDataSheet(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/product/${productId}/delete-datasheet`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePdfImages(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/product/${productId}/delete-pdf-images`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    verifyProductExists(ctx, body) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/products/exists', {
            params: body,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
