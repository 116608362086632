import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function productHandler() {
  // Use toast
  const toast = useToast()

  const refProductsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'product_code', label: 'Código', sortable: false },
    { key: 'name', label: 'Nombre', sortable: false },
    { key: 'price_pen', label: 'Precio (s/)', sortable: false },
    { key: 'price_usd', label: 'Precio ($)', sortable: false },
    { key: 'stock', label: 'Stock', sortable: false },
    { key: 'status_name', label: 'Estado', sortable: false },
    { key: 'photo', label: 'Foto', sortable: false },
    { key: 'updated_at', label: 'F.Actualizacion', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(20)
  const totalProducts = ref(0)
  const productsCategoriesList = ref([])
  const productsBrandList = ref([])
  const currentPage = ref(1)
  const searchQuery = ref('')
  const filterQueryStatus = ref(null)
  const filterQueryCategory = ref('')

  const refetchData = () => {
    refProductsListTable.value.refresh()
  }

  watch([currentPage, searchQuery, filterQueryStatus, filterQueryCategory], () => {
    refetchData()
  })

  const fetchProducts = (ctx, callback) => {
    store
      .dispatch('app-products/fetchProducts', {
        searchText: searchQuery.value,
        category: filterQueryCategory.value,
        status: filterQueryStatus.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results

        callback(data)
        totalProducts.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching quotations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchProductCategories = ctx => {
    store
      .dispatch('app-products/fetchProductCategoriesList')
      .then(response => {
        const { results } = response.data.payload
        productsCategoriesList.value = results
      })
      .catch(e => {
        console.log(e, 'error')
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Product categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchProductBrands = ctx => {
    store
      .dispatch('app-products/fetchProductBrands')
      .then(response => {
        const { results } = response.data.payload
        productsBrandList.value = results
      })
      .catch(e => {
        console.log(e, 'error')
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Product categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveStatusVariant = status => {
    if (status === 'PUBLICO') return 'success'
    if (status === 'PRIVADO') return 'info'
    if (status === 'DESACTIVADO') return 'warning'
    return 'primary'
  }
  const resolveStockVariant = stock => (stock > 0 ? 'success' : 'danger')

  onMounted(() => {
    fetchProductCategories()
    fetchProductBrands()
  })

  return {
    fetchProducts,
    productsCategoriesList,
    productsBrandList,

    tableColumns,
    perPage,
    currentPage,
    totalProducts,

    searchQuery,
    filterQueryStatus,
    filterQueryCategory,

    refProductsListTable,
    refetchData,
    resolveStatusVariant,
    resolveStockVariant,
  }
}
